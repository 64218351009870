import { FormattedHTMLMessage, FormattedMessage } from "gatsby-plugin-intl"
import React from "react"

function Translate({ id, ...props }) {
  return <FormattedMessage id={id} {...props} />
}

export function TranslateHTML({ id, ...props }) {
  return <FormattedHTMLMessage id={id} {...props} />
}

export default Translate
