import { useFormik } from "formik"
import { useCampaignCookies } from "@utils/hooks/useStoreCampaignVariablesInCookies"
import { sendDataToLeadsCenter } from "../leadsCenter"
import { sendDataToMautic } from "../mautic"
import { useLocation } from "@reach/router"

import { goToThanksPage } from "@utils/goToThanksPage"

// use staging form id if porovided,
// otherwhise use mauticFormId 17 as default
// PS.: Just set GATSBY_STG_MAUTIC_FORM_ID in development or staging
const CONTACT_MAUTIC_FORM_ID = process.env.GATSBY_STG_MAUTIC_FORM_ID || 17
const askForBudgetFormName = "solicitarorcamento"

export function useAskForBudget(
  targetMauticFormId = CONTACT_MAUTIC_FORM_ID,
  formName = askForBudgetFormName,
  customValidate = null,
  customSubmit = null
) {
  const campaignData = useCampaignCookies()
  const location = useLocation()
  console.log("campaignData: ", campaignData)
  const formId = new URLSearchParams(location.search).get("form_id")

  const {
    handleSubmit,
    handleChange,
    values,
    isSubmitting,
    errors,
    touched,
  } = useFormik({
    onSubmit:
      customSubmit ||
      (async data => {
        try {
          sendDataToMautic(targetMauticFormId, data, campaignData, "")
          sendDataToLeadsCenter(data, campaignData)
        } finally {
          goToThanksPage(data)
        }
      }),
    validate: customValidate,
    validateOnChange: false,
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      formId,
    },
  })

  return {
    touched,
    errors,
    handleSubmit,
    handleChange,
    values,
    isSubmitting,
  }
}
